import React from 'react';
import { TOPBRED_API_BASE_URL } from '../Utils/StringUtils';
import HorseEntriesTable from './HorseEntriesTable';


class Lowlights extends React.Component {

    constructor(props) {
        super(props);
        console.log("props:" + JSON.stringify(props))
        this.state = { horseInsightsDataGetter: props.horseInsightsDataGetter };
        this.badEntriesDataGetter = this.badEntriesDataGetter.bind(this);
    }

    badEntriesDataGetter() {
        const horseInsightsData = this.state.horseInsightsDataGetter();
        console.log("HorseEntriesTable --> badEntriesDataGetter:" + JSON.stringify(horseInsightsData));
        let entriesData = [];
        let n = 0;
        if (horseInsightsData && horseInsightsData['insights'] && horseInsightsData['insights']['bad_entries']) {
            entriesData = horseInsightsData['insights']['bad_entries'];
            n = Math.min(entriesData.date.length, 5);
        }
        console.log("entriesData:" + JSON.stringify(entriesData));
        console.log("n:" + n);
        return Array.from({ length: n }, (_, index) => {
            return {
                'date': entriesData['date'][index],
                'class_entry_title': entriesData['class_entry_title'][index],
                'rider': entriesData['rider'][index],
                'score': entriesData['score'][index],
                'rank': entriesData['rank'][index],
                'num_entries': entriesData['num_entries'][index],
                'prize_in_euro': entriesData['prize_in_euro'][index],
                'tourney_name': entriesData['tourney_name'][index],
                'event_code': entriesData['event_code'][index],
                'competition_name': entriesData['competition_name'][index],
                'obstacle_height': entriesData['obstacle_height'][index],
                'cmh_video_url': entriesData['cmh_video_url'][index],
            }
        });

    }


    render() {
        return (
            <div>
                <HorseEntriesTable horseEntriesDataGetter={this.badEntriesDataGetter}></HorseEntriesTable>
            </div>
        );
    }
}

class Highlights extends React.Component {

    constructor(props) {
        super(props);
        console.log("props:" + JSON.stringify(props))
        this.state = { horseInsightsDataGetter: props.horseInsightsDataGetter };
        this.goodEntriesDataGetter = this.goodEntriesDataGetter.bind(this);
    }

    goodEntriesDataGetter() {
        const horseInsightsData = this.state.horseInsightsDataGetter();
        console.log("HorseEntriesTable --> goodEntriesDataGetter:" + JSON.stringify(horseInsightsData));
        let entriesData = [];
        let n = 0;
        if (horseInsightsData && horseInsightsData['insights'] && horseInsightsData['insights']['good_entries']) {
            entriesData = horseInsightsData['insights']['good_entries'];
            n = Math.min(entriesData.date.length, 5);
        }
        console.log("entriesData:" + JSON.stringify(entriesData));
        console.log("n:" + n);
        return Array.from({ length: n }, (_, index) => {
            return {
                'date': entriesData['date'][index],
                'class_entry_title': entriesData['class_entry_title'][index],
                'rider': entriesData['rider'][index],
                'score': entriesData['score'][index],
                'rank': entriesData['rank'][index],
                'num_entries': entriesData['num_entries'][index],
                'prize_in_euro': entriesData['prize_in_euro'][index],
                'tourney_name': entriesData['tourney_name'][index],
                'event_code': entriesData['event_code'][index],
                'competition_name': entriesData['competition_name'][index],
                'obstacle_height': entriesData['obstacle_height'][index],
                'cmh_video_url': entriesData['cmh_video_url'][index],
            }
        });

    }


    render() {
        return (
            <div>
                <HorseEntriesTable horseEntriesDataGetter={this.goodEntriesDataGetter}></HorseEntriesTable>
            </div>
        );
    }
}


const tmpHorseInsightsData = {
    'fei_id': '111111'
}

function getHorseInsightsData(feiId) {
    var url = new URL(TOPBRED_API_BASE_URL + '/horse-insights-data/')
    var params = { 'fei_id': feiId.trim() }
    url.search = new URLSearchParams(params).toString();
    console.log('url:\n' + url);
    return fetch(url)
}



export default class Insights extends React.Component {

    constructor(props) {
        super(props);
        console.log("props:" + JSON.stringify(props))
        this.state = { horseInsightsData: tmpHorseInsightsData, feiId: props.feiId };
        this.horseInsightsDataGetter = this.horseInsightsDataGetter.bind(this);
        getHorseInsightsData(props.feiId)
            .then(response => {
                response.json().then(response => {
                    console.log('response: ' + JSON.stringify(response));
                    let newState = {
                        ...this.state,
                        'horseInsightsData': response
                    };
                    this.setState(newState);
                })
            }).catch(() => console.log("fetch failed :-("));
    }

    horseInsightsDataGetter() {
        return this.state.horseInsightsData;
    }


    render() {
        return (
            <div className='pt-8 pb-8'>
                <div className="section-header w-full pt-2 mb-1 pl-2">
                    <div className="text-wrapper-h3">Video Highlights</div>
                </div>
                <Highlights horseInsightsDataGetter={this.horseInsightsDataGetter} />
                <div className="section-header w-full pt-2 mt-6 mb-1 pl-2">
                    <div className="text-wrapper-h3">Video Lowlights</div>
                </div>
                <Lowlights horseInsightsDataGetter={this.horseInsightsDataGetter} />
            </div>
        );
    }

}