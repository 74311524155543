import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


// import PedigreeAnalyticsPage from './Components/PedigreeAnalyticsPage.js';
import HorsePerformancePage from './Components/HorsePerformancePage.js';


import './index.css'; 
import './output.css';
import About from './Components/About.js';
import Contact from './Components/Contact.js';
import Home from './Components/Home.js';
import mixpanel from 'mixpanel-browser';
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init('43c5513a57082273e1bb2b9989096028', {debug: true}); 


function render() {

    ReactDOM.render(
        <BrowserRouter className="w-full">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/horse_performance" element={<HorsePerformancePage />} />
                {/* <Route path="/pedigree_analytics" element={<PedigreeAnalyticsPage />} /> */}
            </Routes>
        </BrowserRouter>,
        document.getElementById('root')
    );
}

render()