import React from 'react';
import { Bar } from "react-chartjs-2";
import {sliceLastMonths, getBarChartDatasetStyleProperties, getYTicksProperties, getXOptions, getChartTitleOptions} from '../Utils/GraphsUtils';

export default class PrizeMoneyGraph extends React.Component {

    

    constructor(props) {
        super(props);
        this.state = { horsePerformanceDataGetter: props.horsePerformanceDataGetter };
    }

    getData(horsePerformanceData) {
        const dataMap = sliceLastMonths(horsePerformanceData['month'], horsePerformanceData['sum_prize_in_euro'], 12)
        var data = {
            labels: dataMap['months'],
            datasets: [
                {
                    label: "Prize money",
                    data: dataMap['values'],
                    ...getBarChartDatasetStyleProperties()
                }
            ]
        };

        return data;
    }

    getOptions() {
        return {
            aspectRatio: 2,
            scales: {
                y: {
                    min: 0,
                    ticks: {
                        ...getYTicksProperties(),
                        callback: function (value) {
                            
                            if(value >= 1000){
                                value = (value/1000) + 'k';
                            }
                            return '€' + value;
                        }
                    },
                    grid: {
                        display: false,
                    }
                },
                x: getXOptions()
            },
            plugins: {
                title: {
                    text: 'Prize Money',
                    ...getChartTitleOptions()
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: true,
                    callbacks: {
                        title: function(context) {
                            let title = context[0].label || '';
                            
                            console.log('title:' + title);
                            return title;
                        },
                        label: function(context) {
                            let label = context.dataset.label || '';
                            console.log('label:' + label);
    
                            if (label) {
                                label += ': ';
                            }
                            
                            if (context.parsed.y !== null) {
                            let value = context.parsed.y
                            if(value >= 1000){
                                value = Math.round(value/1000) + 'k';

                            } else {
                                value = Math.round(value);
                            }
                            label += '€' + value;
                        }
                            return label
                        }
                    },
                    usePointStyle: true
                }
            }
        }
    }

    render() {
        const horsePerformanceData = this.state.horsePerformanceDataGetter();
        let data = this.getData(horsePerformanceData);
        let options = this.getOptions(horsePerformanceData);
        return (
            <div className="w-full grid justify-items-center">
                <div className="w-full">
                    <Bar data={data} options={options} />
                </div>
            </div>
        );
    }

}