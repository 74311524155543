import React from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { TOPBRED_API_BASE_URL } from '../Utils/StringUtils';
import mixpanel from 'mixpanel-browser';

const selectHorseStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: '16px',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        display: 'none', // This will hide the indicator on the right side
      }),
}

const Control = ({ children, ...props }) => {
    
    return (
      <components.Control {...props}>
        <FaSearch size={20} style={{ marginRight: '8px', opacity: 0.5, marginLeft: '8px' }} />
        {children}
      </components.Control>
    );
  };


class SelectHorse extends React.Component {

    constructor(props) {
        super(props);
        console.log('props:' + JSON.stringify(this.props));
        this.state = { feiId: '' };
        console.log('state: ' + JSON.stringify(this.state));
        this.handleChange = this.handleChange.bind(this);
        this.loadHorseOptions = this.loadHorseOptions.bind(this);
        this.onSelectHorseChange = this.onSelectHorseChange.bind(this);
        this.defaultHorseOptions = [];
    }

    handleChange(event) {
        const value = event.target.value;
        console.log('event name: ' + event.target.name);
        console.log('event value: ' + value);
        let newState = {
            ...this.state,
            [event.target.name]: value
        };
        this.setState(newState, () => { console.log('new state: ' + JSON.stringify(this.state)); });
    }


    loadHorseOptions(horseNamePrefix) {
        let url = TOPBRED_API_BASE_URL + '/autocomplete/horse/?prefix=' + horseNamePrefix;
        console.log('horse name prefix: ' + horseNamePrefix);
        console.log('url: ' + url);
        return fetch(url)
            .then(response => response.json())
            .then(data => {
                let horseOptions = data.options;
                console.log('horse options length: ' + horseOptions.length);
                console.log('horse options: ' + JSON.stringify(horseOptions));
                return horseOptions;
            });
    }

    onSelectHorseChange(event) {
        console.log('onSelectHorseChange: ' + JSON.stringify(event));
        let feiId = event.value;
        let dest = '/horse_performance?feiId=' + feiId;
        console.log('click, go to: ' + dest);
        mixpanel.track('search_horse', { 'fei_id': feiId });
        this.props.navigate(dest);

    }

    render() {
        return (
            <div className='flex flex-col w-full items-center text-base pt-14'>
                <div className='w-5/6 pb-20 h-30 rounded-2xl'>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={this.loadHorseOptions}
                        placeholder='Search horse...'
                        onChange={this.onSelectHorseChange}
                        styles={selectHorseStyles}
                        components={{
                            Control: Control
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default function SearchPage() {
    let navigate = useNavigate();
    return (
        <div className='flex flex-col w-full'>
            <SelectHorse navigate={navigate} />
        </div>
    );
}
