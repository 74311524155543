import React from 'react';
import SearchPage from './SearchPage';
import Header from './Header';
import mixpanel from 'mixpanel-browser';


export default class Home extends React.Component {
    
    componentDidMount(){
        mixpanel.track('home_loaded');
    }

    render() {
        return (
            <div>
                <Header />
                <SearchPage />
            </div>
        );
    }
}