import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { SECONDARY_COLOR } from '../Utils/CssConstants.js';

var styles = {
  bmBurgerButton: {
    position: 'relative',
    width: '24px',
    height: '24px',
    left: '0px',
    top: '4px'
  },
  bmBurgerBars: {
    background: SECONDARY_COLOR,
    borderRadius: '4px',
    height: '3px',
    width: '24px',

  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    //   display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

export default class SideBar extends React.Component {

  render() {
    return (
      <div>
        <Menu id="sidebar" className="sidebar-menu" styles={styles} >
          <div className='flex flex-col text-lg'>
            <div className='pb-8'>
              <a id="home" className="menu-item" href="/">Home</a>
            </div>
            <div className='pb-8'>
              <a id="about" className="menu-item" href="/about">About</a>
            </div>
            <div className='pb-8'>
              <a id="contact" className="menu-item" href="/contact">Contact</a>
            </div>
          </div>
        </Menu>
      </div>
    );
  }
}