import React from 'react';


function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

function formatPrize(prizeString) {
    return "€" + prizeString.toLocaleString();
}

function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    } else {
      return str;
    }
  }

export default class HorseEntriesTable extends React.Component {
    constructor(props) {
        super(props);
        console.log("HorseEntriesTable props:" + JSON.stringify(props))
        this.state = { horseEntriesDataGetter: props.horseEntriesDataGetter };

    }

    render() {
        let entriesData = this.state.horseEntriesDataGetter();

        return (
            <div className='flex flex-col items-center justify-center pt-2 pb-2'>
                {entriesData.map((item, index) => (
                    <div className="class-enrty-container w-full pt-2">
                        <div className="class-enrty-content-wrapper pt-3 pb-3 grid grid-rows-4 grid-cols-12">
                            <div className="col-span-10 row-span-1">
                                <div className="ml-4">
                                    <span className="text-wrapper text-xs font-semibold">{item.tourney_name}</span>
                                    <span className="text-wrapper text-xs font-normal pl-2"> {formatDate(item.date)}</span>
                                </div>
                            </div>
                            <div className="col-span-2 row-span-4 flex items-center justify-center">
                                <a href={item.cmh_video_url} target="_blank" rel="noopener noreferrer">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22.5401 6.42C22.4213 5.94541 22.1794 5.51057 21.8387 5.15941C21.4981 4.80824 21.0708 4.55318 20.6001 4.42C18.8801 4 12.0001 4 12.0001 4C12.0001 4 5.12008 4 3.40008 4.46C2.92933 4.59318 2.50206 4.84824 2.16143 5.19941C1.8208 5.55057 1.57887 5.98541 1.46008 6.46C1.1453 8.20556 0.991319 9.97631 1.00008 11.75C0.988863 13.537 1.14285 15.3213 1.46008 17.08C1.59104 17.5398 1.83839 17.9581 2.17823 18.2945C2.51806 18.6308 2.9389 18.8738 3.40008 19C5.12008 19.46 12.0001 19.46 12.0001 19.46C12.0001 19.46 18.8801 19.46 20.6001 19C21.0708 18.8668 21.4981 18.6118 21.8387 18.2606C22.1794 17.9094 22.4213 17.4746 22.5401 17C22.8524 15.2676 23.0064 13.5103 23.0001 11.75C23.0113 9.96295 22.8573 8.1787 22.5401 6.42Z" stroke="#504F4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M9.75 15.02L15.5 11.75L9.75 8.48001V15.02Z" stroke="#504F4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </a>
                            </div>
                            <div className="col-span-10 row-span-1">
                                <div className="ml-4">
                                    <span className="text-wrapper text-xs font-semibold">{truncateString(item.event_code, 7)} • {truncateString(item.competition_name, 20)} • {item.obstacle_height}cm</span>
                                </div>
                            </div>
                            <div className="col-span-10 row-span-1 flex">
                                <div className="ml-4">
                                    <span className="text-wrapper text-xs font-normal">Score: </span>
                                    <span className="text-wrapper text-xs font-semibold">{item.score}</span>
                                </div>
                                <div className="pl-2">
                                    <span className="text-wrapper text-xs font-normal">Rank: </span>
                                    <span className="text-wrapper text-xs font-semibold">{item.rank}</span>
                                </div>
                                <div className="pl-2">
                                    <span className="text-wrapper text-xs font-normal">Prize: </span>
                                    <span className="text-wrapper text-xs font-semibold">{formatPrize(item.prize_in_euro)}</span>
                                </div>
                            </div>
                            <div className="col-span-10 row-span-1 flex">
                                <div className="ml-2">
                                    <span className="text-wrapper text-xs font-medium pl-2"> {item.rider}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                ))}
            </div>
        );
    }
}