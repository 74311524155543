import { PRIMARY_COLOR, SECONDARY_COLOR, NEUTRAL_COLOR } from '../Utils/CssConstants';

export function sliceLastMonths(months, values, monthsToSlice = 12) {

    const today = new Date();
    const endDate = new Date(today.getFullYear(), today.getMonth(), 1);
    // const endDate = new Date(months[months.length - 1] + '-01');

    console.log('endDate: ' + endDate);
    const startDate = endDate.setMonth(endDate.getMonth() - monthsToSlice + 1);
    console.log('startDate: ' + startDate);
    const filteredMonths = months.filter(month => {
        // console.log('month: ' + month);
        // console.log('month v2: ' + new Date(month+ '-01'));
        // console.log('cond: ' + (new Date(month+ '-01') >= startDate));
        return new Date(month + '-01') >= startDate;
    });
    const resultValues = values.slice(values.length - filteredMonths.length);

    console.log('filteredMonths: ' + filteredMonths);
    console.log('resultValues: ' + resultValues);
    return {
        months: filteredMonths,
        values: resultValues
    };
}


export function getMissingIntervals(months, values) {
    const intervals = [];
    const minYs = [];
    const maxYs = [];

    for (let i = 1; i < months.length; i++) {
        const prevMonth = months[i - 1];
        const currMonth = months[i];

        const prevDate = new Date(prevMonth + '-01');
        const currDate = new Date(currMonth + '-01');

        const diffInMonths = (currDate.getFullYear() - prevDate.getFullYear()) * 12 + (currDate.getMonth() - prevDate.getMonth());

        // Get the month after the previous month:
        const afterPrev = new Date(prevDate);
        afterPrev.setMonth(afterPrev.getMonth() + 1);

        // Get the month before the current month:
        const beforeCurr = new Date(currDate);
        beforeCurr.setMonth(beforeCurr.getMonth() - 1);


        if (diffInMonths > 1) {
            intervals.push([afterPrev, beforeCurr]);
            minYs.push(Math.min(values[i - 1], values[i]));
            maxYs.push(Math.max(values[i - 1], values[i]));
        }
    }

    return {
        intervals: intervals,
        minYs: minYs,
        maxYs: maxYs
    };
}

export function padMonthsAndValues(months, values, fillValue = null, sliceLast12M = true) {
    const resultMonths = [];
    const resultValues = [];

    // Convert input months to Date objects for easier comparison
    const startDate = new Date(months[0] + '-01');
    const endDate = new Date(months[months.length - 1] + '-01');

    let currentDate = new Date(startDate);

    // Iterate through all months between the first and last months
    while (currentDate <= endDate) {
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Zero-based index to 2-digit month
        const formattedMonth = `${year}-${month}`;

        resultMonths.push(formattedMonth);

        // Check if the current month exists in the input months array
        const index = months.indexOf(formattedMonth);

        if (index !== -1) {
            // If the month exists, add the corresponding value
            resultValues.push(values[index]);
        } else {
            // If the month is missing, pad with null value
            resultValues.push(fillValue);
        }

        // Move to the next month
        currentDate.setMonth(currentDate.getMonth() + 1);
    }

    if (sliceLast12M) {
        return {
            months: resultMonths.slice(-12),
            values: resultValues.slice(-12),
        };
    }

    return {
        months: resultMonths,
        values: resultValues,
    };
}


export function getBarChartDatasetStyleProperties() {
    return {
        borderRadius: 5,
        borderColor: PRIMARY_COLOR,
        backgroundColor: PRIMARY_COLOR,
        borderWidth: 0
    }
}

export function getYTicksProperties() {
    return {
        color: NEUTRAL_COLOR,
        font: {
            size: 8,
        },
    }
}

export function getXTicksProperties() {
    return {
        color: NEUTRAL_COLOR,
        font: {
            size: 8,
        },
        maxRotation: 45, // Maximum rotation angle in degrees
        minRotation: 45,  // Minimum rotation angle in degrees
    }
}

export function getXOptions() {
    return {
        type: 'time',
        time: {
            parser: 'YYYY-MM',
            unit: 'month',
            displayFormats: {
                month: "MMM, YY", // Format for displaying the tick labels
            },
            tooltipFormat: 'MMMM, YYYY'
        },
        grid: {
            display: false
        },
        ticks: getXTicksProperties(),

    }
}

export function getLineChartDataProperties(isBaseline = false) {
    let borderColor = PRIMARY_COLOR;
    let pointBackgroundColor = PRIMARY_COLOR;
    if (isBaseline) {
        borderColor = SECONDARY_COLOR;
        pointBackgroundColor = SECONDARY_COLOR;
    }

    return {
        pointRadius: 2,
        borderWidth: 1.5,
        borderColor: borderColor,
        pointBackgroundColor: pointBackgroundColor,
        tension: 0.1
    }
}

export function getLineChartLegendOptions() {
    return {
        display: true,
        position: 'bottom',
        maxWidth: 600,
        labels: {
            font: {
                size: 8
            },
            usePointStyle: true,
            padding: 20,
            boxWidth: 1
        },
    }
}


export function getChartTitleOptions() {
    return {
        display: true,
        color: NEUTRAL_COLOR,
        font: {
            size: 14,
            weight: 500,
            family: "Arial"
        }
    }
}
