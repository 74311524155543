import React from 'react';
import { TOPBRED_API_BASE_URL } from '../Utils/StringUtils';
import horsetelexLogoImg from '../Assets/horsetelex_logo_with_text_v2.png';
import horsetelexresultsLogoImg from '../Assets/horsetelexresults_logo_with_text_v2.png';
import cmhLogoImg from '../Assets/cmh_logo_with_text_v2.png';
import feiLogoImg from '../Assets/fei_logo.png';

const emptyHorsePerformanceData = {
    'fei_id': '',
    'cmh_url': '',
    'horsetelex_id': '',
    'horse_name': '',
    'month': [],
    'max_obstacle_height': [],
    'sum_prize_in_euro': [],
    'mean_relative_rank_moving_avg': [],
    'mean_clear_round_moving_avg': [],
    'mean_class_clear_round_rate_moving_avg': [],
    'mean_top_10_moving_avg': [],
    'mean_class_top_10_rate_moving_avg': [],
    'mean_first_round_score_moving_avg': [],
    'mean_class_avg_first_round_score_moving_avg': []
}


function getHorsePerformanceData(feiId) {
    var url = new URL(TOPBRED_API_BASE_URL + '/horse-performance-data/')
    var params = { 'fei_id': feiId.trim() }
    url.search = new URLSearchParams(params).toString();
    console.log('url:\n' + url);
    return fetch(url)
}

export default class HorseLinks extends React.Component {

    constructor(props) {
        super(props);
        console.log("props:" + JSON.stringify(props))
        this.state = { horsePerformanceData: emptyHorsePerformanceData, feiId: props.feiId };
        this.horsePerformanceDataGetter = this.horsePerformanceDataGetter.bind(this);
        getHorsePerformanceData(props.feiId)
            .then(response => {
                response.json().then(response => {
                    console.log('response: ' + JSON.stringify(response));
                    let newState = {
                        ...this.state,
                        'horsePerformanceData': response
                    };
                    this.setState(newState);
                })
            }).catch(() => console.log("fetch failed :-("));
    }

    horsePerformanceDataGetter() {
        return this.state.horsePerformanceData;
    }

    render() {
        return (
            <div className='w-full grid items-center justify-items-center mb-40'>
                <div className='w-full grid grid-cols-4 items-center justify-items-center'>
                    <div className='col-span-1 w-3/4 rounded-md border-slate-500 p-1 shadow-md'>
                        <a href={'https://www.horsetelex.com/horses/pedigree/' + this.state.horsePerformanceData['horsetelex_id']} target="_blank" rel="noopener noreferrer">
                            <img alt="horsetelex" src={horsetelexLogoImg} />
                        </a>
                    </div>
                    <div className='col-span-1 w-3/4 rounded-md border-slate-500 p-1 shadow-md'>
                        <a href={'https://www.horsetelexresults.com/rankings/' + this.state.horsePerformanceData['horsetelex_id']} target="_blank" rel="noopener noreferrer">
                            <img alt="horsetelexresults" src={horsetelexresultsLogoImg} />
                        </a>
                    </div>
                    <div className='col-span-1 w-3/4 rounded-md border-slate-500 p-1 shadow-md'>
                        <a href={'' + this.state.horsePerformanceData['cmh_url']} target="_blank" rel="noopener noreferrer">
                            <img alt="CMH.TV" src={cmhLogoImg} />
                        </a>
                    </div>
                    <div className='col-span-1 w-3/4 rounded-md border-slate-500 p-1 shadow-md flex items-center justify-items-center'>
                        <div className='w-full'>
                        <a href={'https://www.fei.org/horse/' + this.state.horsePerformanceData['fei_id']} target="_blank" rel="noopener noreferrer">
                            <img alt="FEI" src={feiLogoImg} />
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}