import React from 'react';
import 'chartjs-adapter-moment';
import { useSearchParams, useNavigate } from "react-router-dom";

import Insights from './Insights';
import HorsePerformanceGraphs from './HorsePerformanceGraphs';
import HorseLinks from './HorseLinks';
import HorsePerformancePageHeader from './HorsePerformancePageHeader';


export default function HorsePerformancePage() {
    let [searchParams] = useSearchParams();
    let feiId = searchParams.get('feiId');
    let navigate = useNavigate();
    return (
        <div>
            <HorsePerformancePageHeader feiId={feiId} navigate={navigate} />
            <HorsePerformanceGraphs feiId={feiId} />
            <Insights feiId={feiId} />
            <HorseLinks feiId={feiId} />
        </div>
    );

}