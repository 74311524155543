import React from 'react';
import Header from './Header';
import mixpanel from 'mixpanel-browser';

export default class About extends React.Component {
    componentDidMount() {
        mixpanel.track('about_loaded');
    }

    render() {
        return (
            <div>
                <Header />
                <div className='font-sans text-teal-900 pl-4 pr-2'>
                    <div className='text-center text-xl px-2 font-bold pt-20'>
                        Unveiling Equine Potential. Empowering Professionals with Time-Saving, Trust-Boosting Insights.
                    </div>
                    <div className='text-center text-xl px-2 font-bold pt-20'>
                        About Us
                    </div>
                    <div className='text-lg px-2 pt-10 leading-8'>
                        Welcome to topbred, the revolutionary platform designed to elevate the way equestrian professionals discover and assess show jumping horses. As passionate equestrians ourselves, we understand the unique challenges that come with scouting and evaluating these magnificent athletes. Our mission is to empower you with data-driven insights and cutting-edge analytics, enabling you to make informed decisions with confidence.
                    </div>
                    <div className='text-center text-xl px-2 font-bold pt-10'>
                        Our Vision
                    </div>
                    <div className='text-lg px-2 pt-10 pb-20 leading-8'>
                    At topbred, we envision a world where the process of finding the perfect show jumping horse is streamlined, efficient, and remarkably insightful. We believe that every rider, trainer, and enthusiast deserves access to a comprehensive and intuitive platform that not only saves time, but also uncovers the true potential of each horse.
                    </div>
                </div>
            </div>
        );
    }

}