import React from 'react';
import SideBar from './SideBar';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

export default function Header() {
    let navigate = useNavigate();
    const handleClick = event => {
        event.preventDefault();
        let dest = '/';
        console.log('click, go to home (search) page');
        mixpanel.track('go_to_search');
        navigate(dest);
    };

    return (
        <div>
            <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 mb-3">
                <div className="container px-4 mx-auto grid grid-cols-12 items-center">
                    <div className="w-full col-span-2">
                        <SideBar />
                    </div>
                    <div className="w-full col-span-6 header-label pt-2" onClick={handleClick}>
                        <div className="header-text-wrapper">
                            Topbred
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}