import React from 'react';
import { TOPBRED_API_BASE_URL, capitalizeFirstLetter } from '../Utils/StringUtils';
import mixpanel from 'mixpanel-browser';
import { ArrowLeft } from './ArrowLeft.js';

const emptyHorsePerformanceData = {
    'fei_id': '',
    'cmh_url': '',
    'horsetelex_id': '',
    'horse_name': '',
    'month': [],
    'max_obstacle_height': [],
    'sum_prize_in_euro': [],
    'mean_relative_rank_moving_avg': [],
    'mean_clear_round_moving_avg': [],
    'mean_class_clear_round_rate_moving_avg': [],
    'mean_top_10_moving_avg': [],
    'mean_class_top_10_rate_moving_avg': [],
    'mean_first_round_score_moving_avg': [],
    'mean_class_avg_first_round_score_moving_avg': []
}

function getHorsePerformanceData(feiId) {
    var url = new URL(TOPBRED_API_BASE_URL + '/horse-performance-data/')
    var params = { 'fei_id': feiId.trim() }
    url.search = new URLSearchParams(params).toString();
    console.log('url:\n' + url);
    return fetch(url)
}

export default class HorsePerformancePageHeader extends React.Component {

    constructor(props) {
        super(props);
        console.log("props:" + JSON.stringify(props));
        this.state = { horsePerformanceData: emptyHorsePerformanceData, feiId: props.feiId, navigate: props.navigate };
        this.horsePerformanceDataGetter = this.horsePerformanceDataGetter.bind(this);
        this.handleClick = this.handleClick.bind(this);
        getHorsePerformanceData(props.feiId)
            .then(response => {
                response.json().then(response => {
                    console.log('response: ' + JSON.stringify(response));
                    let newState = {
                        ...this.state,
                        'horsePerformanceData': response
                    };
                    this.setState(newState);
                })
            }).catch(() => console.log("fetch failed :-("));
    }

    horsePerformanceDataGetter() {
        return this.state.horsePerformanceData;
    }

    handleClick(event) {
        event.preventDefault();
        let dest = '/';
        console.log('click, go to home (search) page');
        mixpanel.track('back_to_home');
        this.state.navigate(dest);
    }

    render() {
        const horsePerformanceData = this.horsePerformanceDataGetter();
        let horseTitle = horsePerformanceData.horse_name;
        let horsePedigree = capitalizeFirstLetter(horsePerformanceData.sire_name) + ' X ' + capitalizeFirstLetter(horsePerformanceData.dam_name); 
        let horseDetails = horsePerformanceData.year + ' • ' + horsePerformanceData.sex + ' • '  + horsePerformanceData.color;
        return (

            <div className="box  w-full">
                <div className="rectangle-wrapper w-full">
                    <div className="rectangle flex gap-x-4 items-center w-full">
                        <div className="flex-none ml-4">
                            <nav onClick={this.handleClick}>
                                <ArrowLeft className="arrow-left" color="#132B64" />
                            </nav>
                        </div>
                        <div className="label flex-auto w-full">
                            <div className="div-wrapper pt-2 pb-2">
                                <div className="horse-title text-base font-semibold">{horseTitle}</div>
                                <div className="horse-title text-xxs">{horsePedigree}</div>
                                <div className="horse-title text-xxs">{horseDetails}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}