import React from 'react';
import { Chart } from 'chart.js';
import { Line } from "react-chartjs-2";
import annotationPlugin from 'chartjs-plugin-annotation';
import { sliceLastMonths, getYTicksProperties, getXOptions, getLineChartDataProperties, getLineChartLegendOptions, getChartTitleOptions} from '../Utils/GraphsUtils';


Chart.register(annotationPlugin);

export default class ClearRoundRateGraph extends React.Component {

    constructor(props) {
        super(props);
        this.state = { horsePerformanceDataGetter: props.horsePerformanceDataGetter };
    }

    getData(horsePerformanceData) {
        const dataMap = sliceLastMonths(horsePerformanceData['month'], horsePerformanceData['mean_clear_round_moving_avg'], 12)
        const baselineDataMap = sliceLastMonths(horsePerformanceData['month'], horsePerformanceData['mean_class_clear_round_rate_moving_avg'], 12)
        var data = {
            labels: dataMap['months'],
            datasets: [
                {
                    label: "   Clear round rate",
                    data: dataMap['values'],
                    ...getLineChartDataProperties()
                },
                {
                    label: "   Baseline",
                    data: baselineDataMap['values'],
                    ...getLineChartDataProperties(true)
                }
            ]
        };

        return data;
    }

    getOptions() {
        return {
            aspectRatio: 1.5,
            scales: {
                y: {
                    min: 0,
                    max: 1,
                    grid: {
                        display: false
                    },
                    ticks: {
                        callback: function (value) {
                            return (100 * value).toFixed(0) + '%';
                        },
                        stepSize: 0.25,
                        ...getYTicksProperties()
                    }
                },
                x: getXOptions()
            },
            plugins: {
                title: {
                    text: 'Clear Rounds Rate',
                    ...getChartTitleOptions()
                },
                legend: getLineChartLegendOptions(),
                tooltip: {
                    enabled: true,
                    callbacks: {
                        label: function (context) {
                            return context.dataset.label + ': ' + Math.round(100 * context.parsed.y) + '%';
                        }
                    },
                    usePointStyle: true
                }
            }
        }
    }

    render() {
        const horsePerformanceData = this.state.horsePerformanceDataGetter();
        let data = this.getData(horsePerformanceData);
        let options = this.getOptions();
        return (
            <div className="w-full grid justify-items-center">
                <div className="w-full">
                    <Line data={data} options={options} />
                </div>
            </div>
        );
    }

}