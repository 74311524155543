import React from 'react';
import { Line } from "react-chartjs-2";
import {sliceLastMonths, getYTicksProperties, getXOptions, getLineChartDataProperties, getLineChartLegendOptions, getChartTitleOptions} from '../Utils/GraphsUtils';

export default class FirstRoundScoreGraph extends React.Component {

    

    constructor(props) {
        super(props);
        this.state = { horsePerformanceDataGetter: props.horsePerformanceDataGetter };
    }

    getData(horsePerformanceData) {
        const dataMap = sliceLastMonths(horsePerformanceData['month'], horsePerformanceData['mean_first_round_score_moving_avg'], 12)
        const baselineDataMap = sliceLastMonths(horsePerformanceData['month'], horsePerformanceData['mean_class_avg_first_round_score_moving_avg'], 12)
        var data = {
            labels: dataMap['months'],
            datasets: [
                {
                    label: "   Avg score",
                    data: dataMap['values'],
                    ...getLineChartDataProperties(),
                },
                {
                    label: "   Baseline",
                    data: baselineDataMap['values'],
                    ...getLineChartDataProperties(true),
                }
            ]
        };

        return data;
    }

    getOptions() {
        return {
            aspectRatio: 1.5,
            scales: {
                y: {
                    min: 0,
                    grid: {
                        display: false
                    },
                    ticks: {
                        callback: function (value) {
                            return value + ' pts.';
                        },
                        stepSize: 4,
                        ...getYTicksProperties()
                    }
                },
                x: getXOptions()
            },
            plugins: {
                title: {
                    text: 'First Round Score',
                    ...getChartTitleOptions()
                },
                legend: getLineChartLegendOptions(),
                tooltip: {
                    enabled: true,
                    callbacks: {
                        label: function (context) {
                            return context.dataset.label + ': ' + context.parsed.y.toFixed(2)  + ' pts.';
                        }
                    },
                    usePointStyle: true
                }
            }
        }
    }

    render() {
        const horsePerformanceData = this.state.horsePerformanceDataGetter();
        let data = this.getData(horsePerformanceData);
        let options = this.getOptions(horsePerformanceData);
        return (
            <div className="w-full grid justify-items-center">
                <div className="w-full h-full">
                    <Line data={data} options={options} />
                </div>
            </div>
        );
    }

}