import React from "react";

export const ArrowLeft = ({ className }) => {
  return (
    <svg
      className={`arrow-left ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M19 12H5" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      <path
        className="path"
        d="M12 19L5 12L12 5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};